/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl';
import { useAuth } from '../../../../../app/modules/auth';
import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {currentUser && currentUser.userDto.role.roleName.toLowerCase() === 'admin' && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1 font-Vazir'>مدیریت</span>
            </div>
          </div>
          <SidebarMenuItem
            to='/apps/store-management/stores'
            icon='/media/icons/duotune/general/gen017.svg'
            title='تامین کننده'
            fontIcon='bi-shop'
          />

          <SidebarMenuItem
            to='/apps/role-management/roles'
            icon='/media/icons/duotune/general/gen019.svg'
            title='نقش ها'
            fontIcon='bi-bookmarks'
          />

          <SidebarMenuItem
            to='/apps/grade-management/grades'
            icon='/media/icons/duotune/general/gen020.svg'
            title='سطح مشتری'
            fontIcon='bi-award'
          />

          <SidebarMenuItem
            to='/apps/company-management/companies'
            icon='/media/icons/duotune/ecommerce/ecm004.svg'
            title='مشتری'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/brand-management/brands'
            icon='/media/icons/duotune/general/gen017.svg'
            title='کالا'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/advanced-price-management/advanced-prices'
            icon='/media/icons/duotune/general/gen022.svg'
            title='قیمت'
            fontIcon='bi-sticky'
          />
         <SidebarMenuItemWithSub
            to=''
            icon='/media/icons/duotune/general/gen022.svg'
            title='لیست قیمت'
            fontIcon='bi-sticky'
          >
            <SidebarMenuItem
              to='/apps/product-prices/productprices?category=IPHONE%2016'
              icon='/media/icons/duotune/electronics/elc002.svg'
              title='لیست قیمت iPhone 16'
              fontIcon='bi-sticky'
            />
            <SidebarMenuItem
              to='/apps/product-prices/productprices?category=IPHONE%2015'
              icon='/media/icons/duotune/electronics/elc002.svg'
              title='لیست قیمت iPhone 15'
              fontIcon='bi-sticky'
            />
            <SidebarMenuItem
              to='/apps/product-prices/productprices?category=IPHONE%2014'
              icon='/media/icons/duotune/electronics/elc002.svg'
              title='لیست قیمت IPHONE 14'
              fontIcon='bi-sticky'
            />
            <SidebarMenuItem
              to='/apps/product-prices/productprices?category=IPHONE%2013'
              icon='/media/icons/duotune/electronics/elc002.svg'
              title='لیست قیمت IPHONE 13'
              fontIcon='bi-sticky'
            />
            {/* <SidebarMenuItem
              to='/apps/product-prices/productprices?category=IPHONE%2012'
              icon='/media/icons/duotune/electronics/elc002.svg'
              title='لیست قیمت IPHONE 12'
              fontIcon='bi-sticky'
            />
            <SidebarMenuItem
              to='/apps/product-prices/productprices?category=IPHONE'
              icon='/media/icons/duotune/electronics/elc002.svg'
              title='لیست قیمت IPHONE'
              fontIcon='bi-sticky'
            /> */}
            <SidebarMenuItem
              to='/apps/product-prices/productprices?category=IMAC'
              icon='/media/icons/duotune/electronics/elc004.svg'
              title='لیست قیمت MAC'
              fontIcon='bi-sticky'
            />
            <SidebarMenuItem
              to='/apps/product-prices/productprices?category=IWATCH'
              icon='/media/icons/duotune/electronics/elc005.svg'
              title='لیست قیمت WATCH'
              fontIcon='bi-sticky'
            />
            <SidebarMenuItem
              to='/apps/product-prices/productprices?category=IPAD'
              icon='/media/icons/duotune/electronics/elc001.svg'
              title='لیست قیمت IPAD'
              fontIcon='bi-sticky'
            />
            <SidebarMenuItem
              to='/apps/product-prices/productprices?category=ACCESSORIES'
              icon='/media/icons/duotune/electronics/elc006.svg'
              title='لیست قیمت ACCESSORIES'
              fontIcon='bi-sticky'
            />
            </SidebarMenuItemWithSub> 
            <SidebarMenuItem
            to='/apps/customer-price/'
            icon='/media/icons/duotune/ecommerce/ecm003.svg'
            title='لیست قیمت مشتری'
            fontIcon='bi-sticky'
          />
        </>
      )}
      {currentUser && currentUser.userDto.role.roleName.toLowerCase() === 'operator' && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1 font-Vazir'>اپراتور</span>
            </div>
          </div>

          <SidebarMenuItem
            to='/apps/advanced-price-management/advanced-prices'
            icon='/media/icons/duotune/general/gen022.svg'
            title='اطلاعات قیمت ها'
            fontIcon='bi-sticky'
          />
        </>
      )}
      {currentUser && currentUser.userDto.role.roleName.toLowerCase() === 'user' && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1 font-Vazir'>کاربر</span>
            </div>
          </div>

        
          <SidebarMenuItem
            to='/apps/customer-price/'
            icon='/media/icons/duotune/general/gen022.svg'
            title='لیست قیمت ها'
            fontIcon='bi-sticky'
          />
        </>
      )}
      {/* <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='محصولات'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='کاربران' hasBullet={true} />
        {/* <SidebarMenuItem to='/crafted/account/settings' title='تنظیمات' hasBullet={true} /> * /}
      </SidebarMenuItemWithSub> */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>کاربران</span>
        </div>
      </div> */}

      {/* <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export { SidebarMenuMain };

